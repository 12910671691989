@import "../../../../assets/styles/_variable.scss";
.leftnav__item {
  font-size: $text-size--big;
  font-weight: $text-weight--bold;
  color: $light-grey;
  padding: 12px 8px;
  border-bottom: 1px solid rgba($off-white, 0.2);
  > :first-child {
    /* either a link or a wrapper - to align the icon and text */
    display: flex;
    align-items: center;
    align-content: center;
  }
}
.leftnav__item-icon {
  display: flex;
  justify-content: center;
  transform: scale(0.7);
  min-width: 36px;
  margin-right: 8px;
}
