@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";
@import "../../../assets/styles/_layout.scss";

.topnav {
  @extend .flex-row;
  @extend .align-items-centre; // vertical
  @extend .justify-content-end; // right

  @include with-shadow($dark-shadow);

  position: fixed;
  background-color: $light-grey;
  left: 0;
  top: 0;
  width: 100%;
  height: $top-nav-height;
  padding-right: 20px;
  z-index: 1;
  > .topnav__nav-item {
    &:not(:first-child) {
      margin-left: 24px;
    }
  }
}
