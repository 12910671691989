.tactic-summary__list {
  margin-right: 16px;
}

.tactic-summary__list-item {
  display: block;
  margin-bottom: 16px;
  opacity: 0.5;
  transition: opacity 250ms;
  &:last-child {
    margin-bottom: 0;
  }
}

a.tactic-summary__list-item:hover:not(.tactic-summary__list-item--active) {
  opacity: 0.8;
}

.tactic-summary__list-item--active {
  opacity: 1;
}

a {
  text-decoration: none;
}
