@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";

.info-panel__icon {
  @include rounded-corners();
  @include with-shadow($mid-shadow);

  display: flex;
  align-items: center;
  justify-content: center;

  width: $base-unit * 10;
  height: $base-unit * 10;
  margin-top: -$base-unit * 3.5;
  color: $off-white;
}

.info-panel__icon--inverted {
  color: $off-black;
}
