@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.alert-box {
  @include rounded-corners;
  // @include with-shadow($mid-shadow);
  border: 1px solid $lighter-grey;
  background-color: $light-grey;
  padding: $base-unit;
  margin: $base-unit * 3 0;
}
