@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";
@import "../../../../assets/styles/core-styles.scss";

.tactic-summary {
  @include with-padding(1, 2, 1, 2);
  @include rounded-corners();
  @include with-shadow($mid-shadow);
  background-color: $off-white;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tactic-summary__title {
  color: $theme-primary;
  font-weight: $text-weight--bold;
  font-size: $text-size--big;
  width: 200px;
  padding-right: 30px;
}
.tactic-summary__details {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  flex-grow: 1;
  > .tactic-summary__details-value {
    margin-top: 8px;
    font-weight: $text-weight--light;
    font-size: $text-size--larger;
  }
}
