@import "./variable.scss";

@mixin rounded-corners {
  border-radius: $base-unit * 0.75;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin with-shadow($shadow-type) {
  box-shadow: $base-unit * 0.5 $base-unit * 0.5 $base-unit * 1.25 $shadow-type;
}

@mixin with-padding($t, $r, $b, $l) {
  padding: $base-unit * $t $base-unit * $r $base-unit * $b $base-unit * $l;
}
