@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.label-pill {
  @include rounded-corners();
  @include with-padding(0.75, 0.75, 0.75, 0.75);
  background-color: $theme-primary;
  color: $white;
  min-width: $base-unit * 3.75;
  text-align: center;
}
