.loader {
  display: flex;
  align-items: center;
  padding: 16px 0;
  flex-flow: column;
  .bars {
    margin-bottom: 8px;
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    > .bar {
      display: inline-block;
      background-color: steelblue;
      width: 10px;
      height: 10px;
      animation: myAnim 1s infinite ease-in-out;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-radius: 5px;
      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.1s;
      }
      &:nth-child(3) {
        animation-delay: 0.2s;
      }
      &:nth-child(4) {
        animation-delay: 0.3s;
      }
      &:nth-child(5) {
        animation-delay: 0.4s;
      }
    }
  }
}

@keyframes myAnim {
  0% {
    height: 10px;
  }
  50% {
    height: 30px;
  }
  100% {
    height: 10px;
  }
}
