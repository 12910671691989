@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";

.dashboard__panel-header {
  @include with-shadow($dark-shadow);
  @include rounded-corners;

  padding: $base-unit * 2 $base-unit * 2 $base-unit * 3 $base-unit * 2;

  color: $off-white;
  background-color: $theme-primary;

  padding: $base-unit * 2;
  position: relative;
  top: -$base-unit * 4;
  margin: 0 $base-unit * 2 0 0;
}

.dashboard__panel-title {
  font-size: $text-size--larger;
  font-weight: $text-weight--light;
}
