@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.drop-down {
  @include rounded-corners;
  @include with-shadow($dark-shadow);
  @include with-padding(0.25, 0, 0.5, 0);

  position: absolute;
  width: 140px;

  background-color: $light-grey;
  border: 1px solid $grey;

  &.drop-down--hidden {
    display: none;
  }
  > .drop-down__item {
    @include with-padding(0.5, 1, 0.5, 1);
  }
  > .drop-down__separator {
    margin-top: $base-unit * 0.25;
    &:before {
      content: "";
      position: absolute;
      left: $base-unit;
      right: $base-unit;
      height: 1px;
      background-color: $grey;
    }
  }
}
