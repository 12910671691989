@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";
@import "../../../assets/styles/_layout.scss";

.progress-panel {
  @include with-padding(2, 2, 2, 2);
  @include rounded-corners();
  @include with-shadow($mid-shadow);

  @extend .flex-row;
  @extend .flex-grow-columns;
  background-color: $off-white;
}

.progress-panel__left {
  @extend .flex-column;
}
.progress-panel__right {
  @extend .flex-row;
  @extend .justify-content-end;
}

.progress-panel__title {
  padding-bottom: $base-unit * 0.75;
  font-weight: $text-weight--heavy;
  color: $theme-primary;
}

.progress-panel__subtitle {
  font-size: $text-size--small;
  font-weight: $text-weight--light;
}
