@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";
@import "../../../../assets/styles/_layout.scss";

.phase__column {
  @extend .flex-column;
  border-right: 1px solid $light-grey;
  min-height: 500px;
  width: 40%;
  padding: 0 8px 8px;
  &:first-child {
    border-left: 1px solid $light-grey;
  }
}
