@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

$matrix-marker-size: 26px;

.status-marker {
  @include with-shadow($mid-shadow);
  color: $off-white;
  border-radius: 50%;
  width: $matrix-marker-size;
  height: $matrix-marker-size;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 !important;
  border: 2px solid $off-white;

  line-height: 1;
  cursor: help;
  &.status-marker--unadorned {
    box-shadow: none;
  }
}

.status-marker--complete {
  background-color: $complete;
}
.status-marker--completed {
  background-color: $completed;
}
.status-marker--in-progress {
  background-color: $in-progress;
}
.status-marker--not-started {
  background-color: $not-started;
}
.status-marker--blocked {
  background-color: $blocked;
}

.status-marker--stat {
  background-color: $stat;
}
