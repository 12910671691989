@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";
.status-pill {
  @include rounded-corners();

  width: 100%;
  padding: $base-unit * 0.75 0;
  text-align: center;
  color: $off-white;
  font-size: $text-size--small;
  text-transform: uppercase;
  &:hover {
    z-index: 9999;
  }
}

.status-pill--complete {
  background-color: $complete;
}
.status-pill--completed {
  background-color: $completed;
}
.status-pill--in-progress {
  background-color: $in-progress;
}
.status-pill--not-started {
  background-color: $not-started;
}
.status-pill--blocked {
  background-color: $blocked;
}

.status-pill--stat {
  background-color: $stat;
}
