.gantt {
  border: 1px solid silver;
  padding: 10px;
  margin-bottom: 40px;
  overflow-x: auto;
  position: relative;
  height: auto;
  .gantt__layout {
    left: 0;
    top: 0;
    height: 100%;
    height: auto;
    > .gantt__row {
      //   //   justify-content: center;
      border-left: 1px solid silver;
      border-top: 1px solid silver;
      > div {
        border-right: 1px solid silver;
        padding: 6px 0;
        display: flex;
        justify-items: center;
      }
      //   //   &:last-child {
      //   //     border-right: 1px solid silver;
      //   //   }
    }
  }
}
