@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

// -- vars
$bg-color: $theme-primary;
$default-size: 1em;
$label-font-size: $default-size / 3.75;
$label-font-size-redo: $default-size * 3.75;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}

@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate((100 - (50 - $progress)) / 100 * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate((100 - $progress) / 100 * 360deg);
    }
  }
}

.chart {
  font-size: 6em;
  &.chart--small {
    font-size: 3.5em;
  }
  &.chart--x-small {
    font-size: 2.5em;
  }
  &.chart--large {
    font-size: 8em;
  }
}

.charts-container {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  position: relative;

  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, $default-size / 2);
    left: 0;
    position: absolute;
    top: 0;

    .half-circle {
      @include size(100%, 100%);
      border: ($default-size / 10) solid $blue;
      border-radius: 50%;
      clip: rect(0, $default-size / 2, $default-size, 0);
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .label {
    font-weight: $text-weight--heavy;
    background: $bg-color;
    border-radius: 50%;
    bottom: $label-font-size-redo / 10;
    color: $off-white;
    cursor: default;
    display: block;
    font-size: $label-font-size;
    left: $label-font-size-redo / 10;
    line-height: $label-font-size-redo * 0.7;
    position: absolute;
    right: $label-font-size-redo / 10;
    text-align: center;
    top: $label-font-size-redo / 10;

    .smaller {
      color: $grey;
      font-size: 0.6em;
      vertical-align: super;
    }
  }

  .shadow {
    @include size(100%, 100%);
    // this is the incomplete bar
    // border: $default-size / 10 solid #bdc3c7;
    border-radius: 50%;
  }

  &.style-2 {
    .label {
      background: none;
      color: $grey;

      .smaller {
        color: $grey;
      }
    }
  }

  @for $i from 1 through 100 {
    &.progress-#{$i} {
      @include draw-progress($i, #1abc9c);
    }
  }
}
