@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.leftnav {
  width: $left-nav-width;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  overflow: auto;

  background: transparent linear-gradient(180deg, #467075 0%, #578990 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 2px 0px 10px $darkest-shadow;
  color: $off-white;
  z-index: 2;
  .leftnav__brand {
    display: flex;
    justify-content: center;
    height: 70px;
    margin-top: 10px;
    padding: $base-unit * 1.25;
    > img,
    svg {
      height: 100%;
      max-width: 100%;
    }
  }
}
