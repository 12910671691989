@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";

.matrix {
  @include rounded-corners;

  border: 1px solid $light-grey;
  padding: 24px;
  background: transparent
    linear-gradient(
      210deg,
      lighten($theme-primary, 20%) 0%,
      lighten($theme-primary, 40%) 100%
    )
    0% 0% no-repeat padding-box;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 300px);
  position: relative;
  &::after {
    content: "";
    background-color: lighten($theme-primary, 45%);
    width: 1px;
    top: 24px;
    bottom: 24px;
    position: absolute;
    left: calc(50% - 1px);
    z-index: 1;
  }
  &::before {
    content: "";
    background-color: lighten($theme-primary, 45%);
    height: 1px;
    left: 24px;
    right: 24px;
    position: absolute;
    top: calc(50% - 1px);
    z-index: 1;
  }
}
.matrix__quadrant {
  font-weight: $text-weight--light;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  > .matrix__quadrant-sector {
    align-items: center;
    justify-self: center;
    align-self: center;
    position: relative;
  }
  > .matrix__quadrant-title {
    position: absolute;
    &.matrix__quadrant-title--tl {
      left: -16px;
      top: -16px;
    }
    &.matrix__quadrant-title--tr {
      right: -16px;
      top: -16px;
    }
    &.matrix__quadrant-title--bl {
      left: -16px;
      bottom: -16px;
    }
    &.matrix__quadrant-title--br {
      right: -16px;
      bottom: -16px;
    }
  }
}

.axis-label {
  font-weight: $text-weight--light;
  position: absolute;
  z-index: 10;
  padding: 4px 12px;
  border: 1px solid lighten($theme-primary, 45%);
  background-color: #ddd;
  border-radius: 5px;
  &.axis-label--x {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  &.axis-label--y {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}
