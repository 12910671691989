@import "./variable.scss";

.text--small {
  font-size: $text-size--small;
}
.text--normal {
  font-size: $text-size--normal;
}
.text--big {
  font-size: $text-size--big;
}
.text--bigger {
  font-size: $text-size--bigger;
}
.text--large {
  font-size: $text-size--large;
}
.text--larger {
  font-size: $text-size--larger;
}

/* font weights */
@for $i from 1 through 9 {
  .text-weight--#{$i * 100} {
    font-weight: $i * 100;
  }
}

.text--light {
  @extend .text-weight--300;
}
.text--normal {
  @extend .text-weight--400;
}
.text--bold {
  @extend .text-weight--600;
}
.text--heavy {
  @extend .text-weight--800;
}

.text-align--right {
  text-align: right;
}
.text-align--centre {
  text-align: center;
}
