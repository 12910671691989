.centered-page {
  background: transparent linear-gradient(90deg, #5b7b80 0%, #82b2b8 100%) 0% 0%
    no-repeat padding-box;
  width: 100%;
  height: 100vh;
  color: #ebf2f2;
  display: flex;
  flex-direction: column;
  .centered-page__header {
    height: 80px;
    padding-left: 32px;
    display: grid;
    font-weight: 300;
    font-size: 18px;
    align-content: center;
  }
  .centered-page__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: -80px;
  }
  .image {
    height: 70px;
    margin-top: 40px;
  }
}
