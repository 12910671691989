@import "./variable.scss";

@for $i from 1 through 5 {
  .p#{$i} {
    padding: $base-unit * $i;
  }
  .p#{$i}r {
    padding-right: $base-unit * $i;
  }
  .p#{$i}l {
    padding-left: $base-unit * $i;
  }
  .p#{$i}t {
    padding-top: $base-unit * $i;
  }
  .p#{$i}b {
    padding-bottom: $base-unit * $i;
  }
  .m#{$i} {
    margin: $base-unit * $i;
  }
  .m#{$i}r {
    margin-right: $base-unit * $i;
  }
  .m#{$i}l {
    margin-left: $base-unit * $i;
  }
  .m#{$i}t {
    margin-top: $base-unit * $i;
  }
  .m#{$i}b {
    margin-bottom: $base-unit * $i;
  }
}

.flex {
  display: flex;
}
.flex-row {
  @extend .flex;
  flex-direction: row;
}
.flex-column {
  @extend .flex;
  flex-direction: column;
}

@for $i from 2 through 8 {
  .grid-#{$i}-col {
    display: grid;
    grid-template-columns: repeat(#{$i}, 1fr);
    grid-gap: $base-unit * 2;
  }
}

@for $i from 1 to 5 {
  .flex-grow-#{$i} {
    flex: $i $i 0px;
  }
}

.flex-grow-columns {
  flex-direction: row;
  > * {
    flex: 1 1 0px;
  }
}

$alignment: (
  "start": flex-start,
  "centre": center,
  "end": flex-end,
  "stretch": stretch,
  "space-between": space-between,
  "space-around": space-around,
  "space-evenly": space-evenly
);

@each $type, $value in $alignment {
  .align-items-#{$type} {
    align-items: $value;
  }
  .justify-items-#{$type} {
    justify-items: $value;
  }
  .align-content-#{$type} {
    align-content: $value;
  }
  .justify-content-#{$type} {
    justify-content: $value;
  }
  .align-self-#{$type} {
    align-self: $value;
  }
  .justify-self-#{$type} {
    justify-self: $value;
  }
}
