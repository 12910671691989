@import "../../assets/styles/_variable.scss";

.dashboard {
  height: 100%;
  padding: $top-nav-height 0 0 $left-nav-width;
}

.dashboard__content {
  padding: 0 32px 32px;
  > section {
    margin-top: 48px;
  }
}
