@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.simple-grid {
  display: grid;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: $base-unit * 0.5;
  }

  .align-right {
    justify-content: flex-end;
    justify-items: flex-end;
    justify-self: flex-end;
  }
}
.simple-grid--2col {
  grid-template-columns: repeat(2, 1fr);
}
