/* base units */
$base-unit: 8px;
$left-nav-width: $base-unit * 28;
$top-nav-height: $base-unit * 6;

/* colours */
$theme-primary: #587f83;
$red: #f00f24;
$green: #00a13a;
$orange: #ff751a;
$blue: #80bdc2;
$off-black: #3d3d3b;
$darker-grey: lighten(#000000, 30%);
$dark-grey: lighten(#000000, 40%);
$grey: lighten(#000000, 60%);
$lighter-grey: lighten(#000000, 70%);
$lightish-grey: lighten(#000000, 80%);
$light-grey: lighten(#000000, 90%);
$lightest-grey: lighten(#000000, 95%);
$off-white: #efefed;
$white: #ffffff;

$complete: $green;
$completed: $green;
$in-progress: $orange;
$not-started: $grey;
$blocked: $red;
$stat: $off-black; // TEMPORARY

$light-shadow: lighten(#000000, 90%);
$mid-shadow: lighten(#000000, 80%);
$dark-shadow: lighten(#000000, 70%);
$darker-shadow: lighten(#000000, 50%);
$darkest-shadow: lighten(#000000, 30%);

/* fonts */
$text-font: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

/* font sizes */
$text-size--small: ($base-unit * 1.25);
$text-size--normal: ($base-unit * 1.75);
$text-size--big: ($base-unit * 2);
$text-size--bigger: ($base-unit * 2.25);
$text-size--large: ($base-unit * 3);
$text-size--larger: ($base-unit * 3.5);

/* font weights */
$text-weight--light: 300;
$text-weight--regular: 400;
$text-weight--bold: 600;
$text-weight--heavy: 800;

// @import "../../../../assets/styles/_variable.scss";
// @import "../../../../assets/styles/_mixins.scss";

// @include with-padding(1.5, 2, 1.5, 2);
// @include rounded-corners();
// @include with-shadow($mid-shadow);
