@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";
@import "../../../assets/styles/_layout.scss";

.phased-layout {
  @extend .flex-row;
  border-bottom: 1px solid $light-grey;
  > .phase__column {
    @extend .flex-column;
    border-right: 1px solid $light-grey;
    min-height: 500px;
    width: 40%;
    padding: 0 8px 8px;
    &:first-child {
      border-left: 1px solid $light-grey;
    }
    .phase__column--entries {
      flex-grow: 1;
      @extend .flex-column;
      @extend .justify-content-end;
    }
  }
}
