@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.popover {
  @include rounded-corners();
  // @include with-shadow($dark-shadow);

  position: absolute;
  border: 1px solid $theme-primary;
  background-color: $theme-primary;
  color: $off-white;
  padding: $base-unit * 1.25;
  transform: translateY(-50%) translateX(10px);
  max-width: 400px;
  &::before {
    top: 50%;
    content: "";
    position: absolute;
  }
  &.popover--left {
    display: block;
    transform: translateY(-50%) translateX(calc(-100% - 10px));
    &::before {
      right: -20px;
      border-left: 10px solid $theme-primary;
      border-top: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transform: translateY(-50%);
    }
  }
  &.popover--right {
    display: block;
    &::before {
      left: -20px;
      border-right: 10px solid $theme-primary;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      transform: translateY(-50%);
    }
  }
}
