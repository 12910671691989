.situation__header-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin: -16px 0;
  font-weight: 300;
}

.situation__overview {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  column-gap: 24px;
  margin-bottom: 24px;
}
