@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";
@import "../../../../assets/styles/_layout.scss";

.phase__column-header {
  @extend .flex-column;
  @extend .align-items-centre;
  flex-grow: 0;
  margin-bottom: 16px;
}
