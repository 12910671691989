.login {
  .login__title {
    font-size: 20px;
    font-weight: 300;
    margin: 24px 0 16px;
  }
  .login__strap {
    font-size: 50px;
    margin-bottom: 38px;
  }
  .login__form {
    display: flex;
    flex-direction: column;
    > * {
      outline: none;
    }
    > .login__form-errors {
      color: darken($color: red, $amount: 9);
      margin-bottom: 16px;
      border-radius: 20px;
      padding: 8px 12px;
      width: 300px;
      text-align: center;
    }
    > input {
      background-color: transparent;
      border: 1px solid #ebf2f2;
      padding: 10px 16px;
      border-radius: 24px;
      width: 300px;
      margin-bottom: 16px;
      color: white;
      font-size: 14px;
      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &:-ms-input-placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &::-ms-input-placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
      &:disabled {
        opacity: 0.3;
        cursor: wait;
      }
    }
    > button {
      cursor: pointer;
      align-self: center;
      padding: 6px;
      border-radius: 20px;
      border: none;
      width: 140px;
      font-size: 18px;
      color: #467075;
      font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      transition: background-color 150ms ease-in-out;
      &:hover {
        background-color: darken($color: white, $amount: 4);
      }
      &:focus {
        outline: none;
      }
      &:disabled {
        opacity: 0.3;
        cursor: wait;
      }
    }
  }
  .forgot_password{
    padding: 6px;
    margin-top: 10px;
    text-align: center;
  }
}
