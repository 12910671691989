@import "../../../../assets/styles/_variable.scss";
@import "../../../../assets/styles/_mixins.scss";
@import "../../../../assets/styles/_layout.scss";

.table__row {
  display: grid;
  padding: 4px 0;
  border-bottom: 1px solid $off-white;
}

/* TODO: this needs addressing in case we have a flex box inside the content */
.table__row > div {
  @extend .flex;
  @extend .align-items-centre;
  @extend .align-content-centre;
  // @extend .flex;
  padding: 0 8px;
  &.table__row--span-all {
    grid-column: 1/-1;
    @extend .justify-content-centre;
    &.table__row--centered {
      @extend .justify-content-centre;
    }
  }
}
