@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

$matrix-marker-size: 28px;

.matrix-marker {
  background-color: $blue;
  color: $off-white;
  font-weight: $text-weight--bold;
  border-radius: 50%;
  width: $matrix-marker-size;
  height: $matrix-marker-size;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
  transform: translate(-$matrix-marker-size / 2, -$matrix-marker-size / 2);
  z-index: 10;
  border: 2px solid $off-white;
  line-height: 1;
  @include with-shadow($dark-shadow);
  &.matrix-marker--active {
    z-index: 1000;
  }

  > .matrix-marker__detail {
    @include rounded-corners();
    @include with-shadow($dark-shadow);

    position: absolute;
    width: 320px;
    border: 1px solid $off-white;
    background-color: $off-white;
    color: $off-black;
    padding: $base-unit;
    z-index: 10001;
    top: $matrix-marker-size / 2;
    transform: translateY(calc(-50% - 2px));
    display: none;
    &::before {
      top: 50%;
      content: "";
      position: absolute;
    }
    &.matrix-marker__detail--left {
      left: -333px;
      display: block;
      &::before {
        left: 318px;
        border-left: 10px solid $off-white;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: translateY(-50%);
      }
    }
    &.matrix-marker__detail--right {
      left: $matrix-marker-size + 8px;
      display: block;
      &::before {
        left: -20px;
        border-right: 10px solid $off-white;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        transform: translateY(-50%);
      }
    }
  }
}
