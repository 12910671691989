.pill__grid {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid silver;
  > .pill__grid-column {
    border-right: 1px solid silver;
    min-height: 500px;
    width: 25%;
    display: flex;
    flex-direction: column;
    padding: 0 8px 8px;

    &:first-child {
      border-left: 1px solid silver;
    }
    > .pill__grid-column-header {
      display: flex;
      flex-grow: 0;
      margin-bottom: 16px;
      flex-direction: column;
      align-items: center;
    }
    > .pill__grid-entries {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
