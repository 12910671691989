@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.row-flow {
  .row-flow__row {
    @include with-padding(0.25, 0, 0.25, 0);
    &::last-child {
      padding-bottom: 0;
    }
    display: grid;
    column-gap: $base-unit * 0.5;
    &.highlight {
      background-color: $off-white;
    }
  }
  & .row-flow--spacing-lg {
    padding-top: $base-unit * 0.5;
    padding-bottom: $base-unit * 0.5;
    column-gap: $base-unit;
  }
  & .row-flow--spacing-sm {
    padding-top: $base-unit * 0.25;
    padding-bottom: $base-unit * 0.25;
    column-gap: $base-unit * 0.5;
  }
  & .row-flow--spacing-xlg {
    padding-top: 8px;
    padding-bottom: 8px;
    column-gap: $base-unit * 1.5;
  }
}

@for $col from 1 through 10 {
  .row-flow__row--#{$col}col {
    grid-template-columns: repeat(#{$col}, 1fr);
  }
}

/* TODO: This needs some work incase highlightAlternate is not set, the bottom margin is toooo large */
