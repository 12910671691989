@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";

.dashboard__panel {
  @include flex-column;
  @include rounded-corners;
  @include with-shadow($mid-shadow);

  border: 1px solid $lightish-grey;
  padding: $base-unit * 2 $base-unit * 2 $base-unit * 3 $base-unit * 2;
  background-color: $white;
  > *:nth-child(2) {
    margin-top: -$base-unit * 2; /* negates the empty space produced by the negative margin on the header */
  }
}

// TODO: change to use css grid
.dashboard__panel--group {
  > * {
    margin-right: $base-unit * 2;
    &:last-child {
      margin-right: 0;
    }
  }
}
