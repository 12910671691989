@import "./variable.scss";

@import "./_layout.scss";
@import "./_fonts.scss";
@import "./mixins.scss";

body {
  color: $off-black;
  font-size: $text-size--normal;
}

a,
a:visited,
a:active {
  color: $off-black;
  text-decoration: none;
}

a.light,
a.light:visited,
a.light:active {
  color: $off-white;
  text-decoration: none;
  opacity: 0.6;
  &.active {
    color: $white;
    opacity: 1;
  }
}

a.light-grey,
a.light-grey:visited,
a.light-grey:active {
  color: $light-grey;
  text-decoration: none;
  opacity: 0.6;
  &.active {
    color: $lightest-grey;
    opacity: 1;
  }
}

a.grey,
a.grey:visited,
a.grey:active {
  color: $grey;
  text-decoration: none;
  opacity: 0.6;
  &.active {
    color: $lightish-grey;
    opacity: 1;
  }
}

.full-height {
  height: 100%;
}

.fully-centered {
  justify-content: center;
  height: 100%;
}

.rounded {
  @include rounded-corners();
}

.btn {
  @include rounded-corners();
  cursor: pointer;
  padding: $base-unit * 0.5 $base-unit * 1.5;
  background-color: $theme-primary;
  color: $off-white;
  outline: none;
  border: none;
  font-weight: $text-weight--bold;
  font-size: $text-size--normal;
  &.btn--alert {
    background-color: $red;
    color: $off-white;
  }
}

.help {
  cursor: help;
  position: relative;
  &:hover {
    z-index: 9999;
  }
}

/* colours */
$cols: (
  "theme": $theme-primary,
  "red": $red,
  "green": $green,
  "orange": $orange,
  "blue": $blue,
  "off-black": $off-black,
  "off-white": $off-white,
  "darker-grey": $darker-grey,
  "dark-grey": $dark-grey,
  "grey": $grey,
  "lighter-grey": $lighter-grey,
  "lightish-grey": $lightish-grey,
  "light-grey": $light-grey,
  "lightest-grey": $lightest-grey,
  "white": $white
);

@each $col, $value in $cols {
  a.#{$col},
  a.#{$col}:visited,
  a.#{$col}:active {
    color: $value;
    text-decoration: none;
    opacity: 0.6;
    &.active {
      color: $value;
      opacity: 1;
    }
  }

  .text-#{$col} {
    color: $value !important;
  }
  .bg-#{$col} {
    background-color: $value !important;
  }
  .bdr-#{$col} {
    border-color: $value !important;
  }
  .bdr-t-#{$col} {
    border-color: $value !important;
  }
  .bdr-r-#{$col} {
    border-color: $value !important;
  }
  .bdr-b-#{$col} {
    border-color: $value !important;
  }
  .bdr-l-#{$col} {
    border-color: $value !important;
  }
}
