@import "../../../assets/styles/_variable.scss";
@import "../../../assets/styles/_mixins.scss";
@import "../../../assets/styles/_layout.scss";

.info-panel {
  @include with-padding(1.5, 2, 1.5, 2);
  @include rounded-corners();
  @include with-shadow($mid-shadow);

  border: 1px solid $light-grey;
  background-color: $white;
}

.info-panel__top {
  @extend .flex-row;
  @extend .align-items-stretch;

  &.info-panel__top--with-seperator {
    border-bottom: 1px solid $light-grey;
    padding-bottom: $base-unit * 2;
    margin-bottom: $base-unit * 1.5;
  }
}

.info-panel__top-content {
  @extend .flex-column;
  @extend .align-items-end;
  flex-grow: 1;
  font-weight: $text-weight--light;
}

.info-panel__title {
  font-size: $text-size--normal;
  padding-bottom: $base-unit * 0.75;
}

.info-panel__subtitle {
  @extend .flex-row;
  @extend .align-items-end;
  font-size: $text-size--large;
}
